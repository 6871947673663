import request from '../api/api';
import { PaginatedResult, SpacesPagingParams } from '@/app/models/paginate';
import { SpaceModel } from '@/app/models/space';
import { UserModel } from '@/app/models/user';

const spacesServices = {
  list: (params: SpacesPagingParams): Promise<PaginatedResult<SpaceModel[]>> =>
    request.get<PaginatedResult<SpaceModel[]>>('/spaces', params),
  maxUserLimit: (data: any): Promise<any> => request.post<any>(`/spaces/maxUsersLimit`, data),
  listUser: (spaceId: string): Promise<UserModel[]> =>
    request.get<UserModel[]>(`/spaces/${spaceId}/users`, {}),
  createUser: (spaceId: string, data: { email: string; role: string }): Promise<any> =>
    request.post<any>(`/spaces/${spaceId}/share`, data),
  createMonitor: (spaceId: string, data: { email: string }): Promise<any> =>
    request.post<any>(`/spaces/${spaceId}/shareToMonitor`, data),
  linkCompany: (spaceId: string, data: { companyId: string }): Promise<any> =>
    request.put<any>(`/spaces/${spaceId}/companies`, data),
  unlinkUser: (userId: string, spaceId: string): Promise<any> =>
    request.delete<any>(`/spaces/${spaceId}/users/${userId}`),
  editPayBilling: (spaceId: string | null, data: any): Promise<any> =>
    request.put<any>(`/spaces/${spaceId}/billing`, data),
  dowloandReport: () => request.getBlob(`/spaces/report`, {}),
};

export default spacesServices;
