import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Device } from '@/app/models/device';
import { DevicePagingParams, Pagination } from '@/app/models/paginate';
import { ErrorHandler } from '@/app/models/error';
import removeEmpty from '@/app/common/functions/remove-empty';
import devicesServices from '@/app/services/devices-services';
import { FilterDeviceModal, FormDeviceModal } from '@/app/models/modal';

@Module({ namespaced: true })
class DeviceModule extends VuexModule {
  /***************** Start - States *****************/
  _devices: Device[] = [];
  _initialLoading = false;
  _pagination: Pagination | null = null;
  _searchMode = false;
  _pagingParams = new DevicePagingParams(1, 8);
  _error = new ErrorHandler();
  _modalDeviceForm: FormDeviceModal | null = null;
  _modalFilterDevice: FilterDeviceModal | null = null;
  _filterMode = false;

  /*Reset default states*/
  @Mutation
  resetState(): void {
    this._devices = [];
    this._searchMode = false;
    this._initialLoading = false;
    this._filterMode = false;
    this._pagination = null;
    this._pagingParams = new DevicePagingParams(1, 8);
    this._modalDeviceForm = null;
    this._error = new ErrorHandler();
  }
  /***************** End - States *****************/

  /***************** Start - Getters *****************/
  get initialLoading(): boolean {
    return this._initialLoading;
  }
  get devices(): Device[] {
    return this._devices;
  }
  get searchMode(): boolean {
    return this._searchMode;
  }
  get query(): string {
    return this._pagingParams.query;
  }
  get pagination(): Pagination | null {
    return this._pagination;
  }
  get pagingParams(): DevicePagingParams {
    return removeEmpty<DevicePagingParams>(this._pagingParams);
  }
  get error(): ErrorHandler {
    return this._error;
  }
  get modalFormDeviceRef(): FormDeviceModal | null {
    return this._modalDeviceForm;
  }
  get modeFilter(): boolean {
    return this._filterMode;
  }
  get modalFilterDeviceRef(): FilterDeviceModal | null {
    return this._modalFilterDevice;
  }

  /***************** End - Getters *****************/

  /**************** Start - Mutations *****************/
  @Mutation
  setDevices(devices: Device[]): void {
    this._devices = devices;
  }
  @Mutation
  setInitialLoading(val: boolean): void {
    this._initialLoading = val;
  }
  @Mutation
  setPagination(pagination: Pagination | null): void {
    this._pagination = pagination;
  }
  @Mutation
  setQuery(query: string): void {
    this._pagingParams.query = query;
  }
  @Mutation
  setSearchMode(val: boolean): void {
    this._searchMode = val;
  }
  @Mutation
  setPaginate(page: number): void {
    if (!this._pagination) return;
    this._pagingParams.page = page;
    this._pagination.page = page;
  }
  @Mutation
  setPaginateLinkStatus(status?: string): void {
    this._pagingParams.linkStatus = status;
  }
  @Mutation
  setError(error: ErrorHandler): void {
    this._error = error;
  }
  @Mutation
  setDefaultError(): void {
    this._error = new ErrorHandler();
  }
  @Mutation
  setModalDeviceForm(modal: FormDeviceModal): void {
    this._modalDeviceForm = modal;
  }
  @Mutation
  setFilterMode(val: boolean): void {
    this._filterMode = val;
  }
  @Mutation
  setModalFilterDevice(modal: FilterDeviceModal): void {
    this._modalFilterDevice = modal;
  }
  /**************** End - Mutations *****************/

  /**************** Start - Actions ****************/
  @Action({ rawError: true })
  async loadDevices(): Promise<void> {
    this.context.commit('setInitialLoading', true);
    this.context.commit('setDefaultError');
    try {
      const response = await devicesServices.list(this.pagingParams);
      this.context.commit('setDevices', response.data);
      const pagination = new Pagination(
        response.page,
        response.pages,
        response.perPage,
        response.total
      );
      this.context.commit('setPagination', pagination);
    } catch (error) {
      const errorHandler = new ErrorHandler(error);
      this.context.commit('setError', errorHandler);
    } finally {
      this.context.commit('setInitialLoading', false);
    }
  }

  @Action
  searchEnd(): void {
    this.context.commit('setQuery', '');
    this.context.commit('setSearchMode', false);
    this.context.dispatch('loadDevices');
  }
  /**************** End - Actions ****************/
}
export default DeviceModule;
