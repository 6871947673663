import Vue from 'vue';
import App from './app/App.vue';
import store from './app/store';
import vuetify from './app/plugins/vuetify';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
const toast = Vue.use(Toast, {});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import awsCognitoConfig from './app/aws/aws-cognito/aws-cognito-config';
import Amplify, { API } from 'aws-amplify';
import { Hub } from 'aws-amplify';
import router from './app/router';

import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);

Amplify.configure(awsCognitoConfig);
API.configure(awsCognitoConfig);

/*const logger = new Logger('My-Logger');*/
const listener = (data: any) => {
  switch (data.payload.event) {
    case 'signIn':
      break;
    case 'signUp':
      break;
    case 'signOut':
      break;
    case 'signIn_failure':
      break;
    case 'tokenRefresh':
      break;
    case 'tokenRefresh_failure':
      store.dispatch('auth/signOut').finally(() => {
        toast.$toast.error('Inicie sesión nuevamente');
      });
      break;
    case 'configured':
  }
};

Hub.listen('auth', listener);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
