import { GetterTree } from 'vuex';
import { UserState } from './state';
import { StateInterface } from '@/app/store';
import { ErrorHandler } from '@/app/models/error';
import { Pagination, UsersPagingParams } from '@/app/models/paginate';
import removeEmpty from '@/app/common/functions/remove-empty';
import { UserModel } from '@/app/models/user';

const getters: GetterTree<UserState, StateInterface> = {
  isLoading(state): boolean {
    return state.isLoading;
  },
  error(state): ErrorHandler {
    return state.error;
  },
  searchMode(state): boolean {
    return state.searchMode;
  },
  filterMode(state): boolean {
    return state.filterMode;
  },
  users(state): UserModel[] {
    return state.users;
  },
  query(state): string {
    return state.pagingParams.query;
  },
  pagination(state): Pagination | null {
    return state.pagination;
  },
  pagingParams(state): UsersPagingParams {
    return removeEmpty<UsersPagingParams>(state.pagingParams);
  },
};

export default getters;
