import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
class ModalErrorModule extends VuexModule {
  _dialog = false;
  _message = '';
  _title = 'MENSAJE';

  /***************** Start - Getters *****************/
  get dialog(): boolean {
    return this._dialog;
  }

  get message(): string {
    return this._message;
  }

  get title(): string {
    return this._title;
  }
  /***************** End - Getters *****************/

  /**************** Start - Mutations *****************/
  @Mutation
  setDialog(val: boolean): void {
    this._dialog = val;
  }
  @Mutation
  setMessage(val: string): void {
    this._message = val;
  }
  @Mutation
  setTitle(val: string): void {
    this._title = val;
  }
  /**************** End - Mutations *****************/

  /**************** Start - Actions *****************/
  @Action({ rawError: true })
  handleModalError({ response, title }: { response: any; title?: string }): void {
    const msg: string = response?.data?.error?.message || 'Ocurrió un error';
    this.context.commit('setDialog', true);
    this.context.commit('setMessage', msg);
    if (title) this.context.commit('setTitle', title);
  }

  @Action({ rawError: true })
  modalCustomError({ message, title }: { message: string; title?: string }): void {
    const msg: string = message;
    this.context.commit('setDialog', true);
    this.context.commit('setMessage', msg);
    if (title) this.context.commit('setTitle', title);
  }
  /**************** End - Actions *****************/
}
export default ModalErrorModule;
