import { MutationTree } from 'vuex';
import { UserState } from './state';
import { ErrorHandler } from '@/app/models/error';
import { UsersPagingParams, Pagination } from '@/app/models/paginate';
import {UserModel} from "@/app/models/user";

const mutation: MutationTree<UserState> = {
  resetStatus(state): void {
    state.users = [];
    state.searchMode = false;
    state.isLoading = true;
    state.pagination = null;
    state.pagingParams = new UsersPagingParams(1, 8);
    state.error = new ErrorHandler();
  },
  /*  restoreUsersPagingParams(state, perPage): void {
    state.pagingParams = new UsersPagingParams(1, perPage);
    state.itemsForPage = perPage;
  },*/
  setUsers(state, users: UserModel[]): void {
    state.users = users;
  },
  setIsLoading(state, val: boolean): void {
    state.isLoading = val;
  },
  setFilterMode(state, val: boolean): void {
    state.filterMode = val;
  },
  setSearchMode(state, val: boolean): void {
    state.searchMode = val;
  },
  setPagination(state, pagination: Pagination | null): void {
    state.pagination = pagination;
  },
  setQuery(state, query: string): void {
    state.pagingParams.query = query;
  },
  setPaginateQuery(state, query: string | null): void {
    state.pagingParams.query = query || '';
    state.searchMode = !!query;
  },
  setPaginate(state, page: number): void {
    if (!state.pagination) return;
    state.pagingParams.page = page;
    state.pagination.page = page;
  },
  setError(state, error: ErrorHandler): void {
    state.error = error;
  },
  setDefaultError(state): void {
    state.error = new ErrorHandler();
  },
};

export default mutation;
