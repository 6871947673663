import request from '../api/api';
import { PaginatedResult, UsersPagingParams } from '@/app/models/paginate';
import { UserFormValues, UserModel } from '@/app/models/user';

const usersServices = {
  list: (params: UsersPagingParams): Promise<PaginatedResult<UserModel[]>> =>
    request.get<PaginatedResult<UserModel[]>>('/users', params),
  add: (user: UserFormValues): Promise<void> => request.post<void>('/users', user),
  update: (user: UserFormValues): Promise<void> => request.put<void>(`/users/${user._id}`, user),
  delete: (user: UserModel): Promise<void> => request.delete<void>(`/users/${user._id}`),
  maxSpaceLimit: (data: any): Promise<void> => request.post<void>(`/spaces/maxSpaceLimit`, data),
  changePassword: (userId: string, data: { newPassword: string }): Promise<void> =>
    request.post<void>(`/users/${userId}/resetPassword`, data),
};

export default usersServices;
