import { Module } from 'vuex';
import { StateInterface } from '@/app/store';

import state, { SpaceState } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const spaceModule: Module<SpaceState, StateInterface> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default spaceModule;
