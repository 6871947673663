import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
class SnackbarModule extends VuexModule {
  /***************** Start - States *****************/
  _snackbar = false;
  _knowledge = 0;
  /*Reset default states*/
  @Mutation
  resetState(): void {
    this._snackbar = false;
    this._knowledge = 0;
  }
  /***************** End - States *****************/

  /***************** Start - Getters *****************/
  get snackbar(): boolean {
    return this._snackbar;
  }
  get knowledge(): number {
    return this._knowledge;
  }
  /***************** End - Getters *****************/

  /**************** Start - Mutations *****************/
  @Mutation
  setSnackbar(snackbar: boolean): void {
    this._snackbar = snackbar;
  }
  @Mutation
  setKnowledge(val: number): void {
    this._knowledge = val;
  }
  /**************** End - Mutations *****************/

  /**************** Start - Actions ****************/
  /**************** End - Actions ****************/
}
export default SnackbarModule;
