import { MutationTree } from 'vuex';
import { AuthState } from './state';

const mutation: MutationTree<AuthState> = {
  setLoading(state, val: boolean): void {
    state.loadingLogin = val;
  },

  setUserAuth(state, userAuth: any): void {
    if (userAuth) {
      const name = userAuth.signInUserSession.idToken.payload.name;
      const lastname = userAuth.signInUserSession.idToken.payload.family_name;
      const token = userAuth.signInUserSession.idToken.jwtToken;
      const user = userAuth.username;
      const roles = userAuth.signInUserSession.accessToken.payload['cognito:groups'];
      if (token && user && roles) {
        state.userAuth = {
          fullName: `${name || ''} ${lastname || ''}`,
          username: user,
          role: roles.includes('ROOT') ? 'ROOT' : undefined,
          roles: roles,
          name,
        };
      }
    }
    if (!userAuth) {
      state.userAuth = null;
    }
  },

  setLoadingApp(state, val: boolean): void {
    state.loadingApp = val;
  },
};

export default mutation;
