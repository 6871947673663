import { Pagination, UsersPagingParams } from '@/app/models/paginate';
import { ErrorHandler } from '@/app/models/error';
import { UserModel } from '@/app/models/user';

export interface UserState {
  users: UserModel[];
  isLoading: boolean;
  pagination: Pagination | null;
  pagingParams: UsersPagingParams;
  error: ErrorHandler;
  searchMode: boolean;
  filterMode: boolean;
}

function state(): UserState {
  return {
    users: [],
    isLoading: false,
    pagination: null,
    pagingParams: new UsersPagingParams(1, 8),
    error: new ErrorHandler(),
    searchMode: false,
    filterMode: false,
  };
}

export default state;
