import request from '../api/api';
import { DevicePagingParams, PaginatedResult } from '@/app/models/paginate';
import { Device, DeviceFormValues } from '@/app/models/device';

const devicesServices = {
  list: (params: DevicePagingParams): Promise<PaginatedResult<Device[]>> =>
    request.get<PaginatedResult<Device[]>>('/devices/space/root', params),
  add: (data: DeviceFormValues): Promise<void> => request.post<void>('/devices', data),
  delete: (deviceId: string): Promise<void> => request.delete<void>(`/devices/${deviceId}`),
  uploadFile: (deviceId: string, base64: string): Promise<void> =>
    request.post<void>(`/devices/${deviceId}/setup`, { file: base64 }),
  getUrl: (deviceId: string): Promise<{ url: string }> =>
    request.get<{ url: string }>(`/devices/${deviceId}/getUrl`, {}),
};

export default devicesServices;
