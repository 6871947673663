import { CompaniesPagingParams, PaginatedResult } from '@/app/models/paginate';
import { CompanyFormValues, CompanyModel } from '@/app/models/company';
import request from '@/app/api/api';

const companiesServices = {
  list: (params: CompaniesPagingParams): Promise<PaginatedResult<CompanyModel[]>> =>
    request.get<PaginatedResult<CompanyModel[]>>('/companies', params),
  add: (company: CompanyFormValues): Promise<void> => request.post<void>('/companies', company),
  edit: (company: CompanyFormValues): Promise<void> =>
    request.put<void>(`/companies/${company._id}`, company),
};
export default companiesServices;
