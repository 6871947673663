export default {
  theme: {
    themes: {
      light: {
        primary: '#f57c00',
        secondary: '#ff6659',
        accent: '#fff',
        info: '#00CAE3',
        success: '#4CAF50',
        warning: '#FB8C00',
        error: '#FF5252',
      },
      dark: {
        primary: '#f57c00',
        secondary: '#ff6659',
        accent: '#fff',
        info: '#00CAE3',
        success: '#4CAF50',
        warning: '#FB8C00',
        error: '#FF5252',
      },
    },
  },
};
