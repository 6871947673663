export class ErrorHandler {
  exist = false;
  type?: string = undefined;
  code?: number = undefined;
  message?: string = 'Ups, Ocurrió un error';
  constructor(error?: any) {
    if (error) {
      this.exist = true;
      if (!error.message) return;
      if (error?.message === 'Network Error') {
        this.message = 'Compruebe su conexión a internet';
        this.type = 'Network Error';
        return;
      }
      if (!error.response) return;
      this.code = error.response.status;
      if (error.response.status === 400) {
        this.message = error.response?.data?.errors?.message || this.message;
        return;
      }
      if (error.response.status >= 500) {
        this.message = 'Error de servidor';
        return;
      }
    }
  }
}

export interface ErrorModel {
  data: Data;
  status: number;
  statusText: string;
  headers: Headers;
  config: Config;
  request: Request;
}

interface Data {
  error: Error;
}

interface Error {
  message: string;
  code: number;
}

interface Headers {
  'content-length': string;
  'content-type': string;
}

interface Config {
  url: string;
  method: string;
  data: any;
  headers: Headers2;
  transformRequest: any[];
  transformResponse: any[];
  timeout: number;
  responseType: string;
  xsrfCookieName: string;
  xsrfHeaderName: string;
  maxContentLength: number;
  maxBodyLength: number;
  /*  cancelToken: CancelToken;*/
  transitional: Transitional;
  host: string;
  path: string;
}

interface Headers2 {
  Accept: string;
  'x-amz-date': string;
  'X-Amz-Security-Token': string;
  Authorization: string;
}

/*interface CancelToken {
  promise: Promise;
}*/

/*interface Promise {}*/

interface Transitional {
  silentJSONParsing: boolean;
  forcedJSONParsing: boolean;
  clarifyTimeoutError: boolean;
}

/*interface Request {}*/