import { ActionTree } from 'vuex';
import { UserState } from './state';
import { StateInterface } from '@/app/store';
import { Pagination } from '@/app/models/paginate';
import { ErrorHandler, ErrorModel } from '@/app/models/error';
import Vue from 'vue';
import Toast from 'vue-toastification';
import usersServices from '@/app/services/users.services';
import { UserFormValues, UserModel } from '@/app/models/user';

const $toast = Vue.use(Toast, {}).$toast;

const actions: ActionTree<UserState, StateInterface> = {
  async loadUsers({ commit, getters }): Promise<void> {
    commit('setIsLoading', true);
    commit('setDefaultError');
    try {
      const response = await usersServices.list(getters.pagingParams);
      commit('setUsers', response.data);
      const pagination = new Pagination(
        response.page,
        response.pages,
        response.perPage,
        response.total
      );
      commit('setPagination', pagination);
    } catch (error) {
      const errorHandler = new ErrorHandler(error);
      commit('setError', errorHandler);
    } finally {
      commit('setIsLoading', false);
    }
  },
  async addUser({ dispatch }, user: UserFormValues): Promise<void> {
    try {
      await usersServices.add(user);
      $toast.success('Usuario Agregado Correctamente');
    } catch (err) {
      dispatch('apiGatewayInterceptor/handleApiGatewayInterceptor', err.response as ErrorModel, {
        root: true,
      });
      throw err;
    }
  },
  async editUser({ dispatch }, user: UserFormValues): Promise<void> {
    try {
      await usersServices.update(user);
      $toast.success('Usuario Editado Correctamente');
    } catch (err) {
      dispatch('apiGatewayInterceptor/handleApiGatewayInterceptor', err.response as ErrorModel, {
        root: true,
      });
      throw err;
    }
  },
  async deleteUser({ dispatch }, user: UserModel): Promise<void> {
    try {
      await usersServices.delete(user);
      $toast.success('Usuario Eliminado Correctamente');
      dispatch('loadUsers');
    } catch (err) {
      dispatch('apiGatewayInterceptor/handleApiGatewayInterceptor', err.response as ErrorModel, {
        root: true,
      });
      throw err;
    }
  },
  /*  async editUser({ dispatch }, company: CompanyFormValues): Promise<void> {
    try {
      await usersServices.edit(company);
      $toast.success('Compañia Editado Correctamente');
    } catch (err) {
      dispatch('apiGatewayInterceptor/handleApiGatewayInterceptor', err.response as ErrorModel, {
        root: true,
      });
      throw err;
    }
  },*/
  searchEnd({ commit, dispatch }): void {
    commit('setPaginateQuery', null);
    commit('setQuery', '');
    dispatch('loadUsers');
  },
};

export default actions;
