import { ActionTree } from 'vuex';
import { CompanyState } from './state';
import { StateInterface } from '@/app/store';
import companiesServices from '@/app/services/companies.services';
import { Pagination } from '@/app/models/paginate';
import { ErrorHandler, ErrorModel } from '@/app/models/error';
import { CompanyFormValues } from '@/app/models/company';
import Vue from 'vue';
import Toast from 'vue-toastification';

const $toast = Vue.use(Toast, {}).$toast;

const actions: ActionTree<CompanyState, StateInterface> = {
  async loadCompanies({ commit, getters }): Promise<void> {
    commit('setIsLoading', true);
    commit('setDefaultError');
    try {
      const response = await companiesServices.list(getters.pagingParams);
      commit('setCompanies', response.data);
      const pagination = new Pagination(
        response.page,
        response.pages,
        response.perPage,
        response.total
      );
      commit('setPagination', pagination);
    } catch (error) {
      const errorHandler = new ErrorHandler(error);
      commit('setError', errorHandler);
    } finally {
      commit('setIsLoading', false);
    }
  },
  async addCompany({ dispatch }, company: CompanyFormValues): Promise<void> {
    try {
      await companiesServices.add(company);
      $toast.success('Compañia Agregado Correctamente');
    } catch (err) {
      dispatch('apiGatewayInterceptor/handleApiGatewayInterceptor', err.response as ErrorModel, {
        root: true,
      });
      throw err;
    }
  },
  async editCompany({ dispatch }, company: CompanyFormValues): Promise<void> {
    try {
      await companiesServices.edit(company);
      $toast.success('Compañia Editado Correctamente');
    } catch (err) {
      dispatch('apiGatewayInterceptor/handleApiGatewayInterceptor', err.response as ErrorModel, {
        root: true,
      });
      throw err;
    }
  },
  searchEnd({ commit, dispatch }): void {
    commit('setPaginateQuery', null);
    commit('setQuery', '');
    dispatch('loadCompanies');
  },
};

export default actions;
