import { API } from 'aws-amplify';
import { API_ENDPOINT_NAME } from '@/app/common/constants/app-constants';
const apiName = API_ENDPOINT_NAME;

const request = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  get: <T>(url: string, body: {}): Promise<T> =>
    API.get(apiName, url, {
      queryStringParameters: { ...body, t: Date.now() },
    }),
  // eslint-disable-next-line @typescript-eslint/ban-types
  getBlob: <T>(url: string, body: {}): Promise<T> =>
    API.get(apiName, url, {
      responseType: 'blob',
      queryStringParameters: { ...body, t: Date.now() },
    }),
  // eslint-disable-next-line @typescript-eslint/ban-types
  post: <T>(url: string, body: {}): Promise<T> => API.post(apiName, url, { body: body }),
  // eslint-disable-next-line @typescript-eslint/ban-types
  put: <T>(url: string, body: {}): Promise<T> =>
    API.put(apiName, url, { response: true, body: body }),
  // eslint-disable-next-line @typescript-eslint/ban-types
  patch: <T>(url: string, body: {}): Promise<T> =>
    API.patch(apiName, url, { response: true, body: body }),
  delete: <T>(url: string): Promise<T> => API.del(apiName, url, { response: true }),
};

export default request;
