import { ActionTree } from 'vuex';
import { AuthState } from './state';
import { StateInterface } from '@/app/store';
import { SignInFormValues } from '@/app/models/auth';
import { Auth } from 'aws-amplify';
import router from '@/app/router';
/*import { GLOBAL_NAME_ROLE } from '@/app/common/helpers/roles';*/
import Vue from 'vue';
import Toast from 'vue-toastification';
const toast = Vue.use(Toast, {}).$toast;

const actions: ActionTree<AuthState, StateInterface> = {
  async signIn({ commit, dispatch }, authFormValues: SignInFormValues): Promise<void> {
    commit('setLoading', true);
    try {
      await Auth.signIn(authFormValues.email, authFormValues.password);
      await Auth.signOut({ global: true });
      const login = await Auth.signIn(authFormValues.email, authFormValues.password);
      if (login?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log(login);
        await router.push({
          name: 'newPasswordRequired',
          params: { user: login, pass: authFormValues.password },
        });
      } else {
        commit('setUserAuth', login);
        await dispatch('checkUser');
      }
      commit('setLoading', false);
      /*commit('setIsLogin', true);*/
    } catch (error) {
      commit('setLoading', false);
      switch (error.code) {
        case 'NotAuthorizedException':
          if (error.message === 'Incorrect username or password.') {
            toast.error('Usuario o contraseña no válida.');
          } else if (error.message === 'User is disabled.') {
            toast.error('Usuario deshabilitado. Contacte con el administrador.');
          } else if (error.message === 'Password attempts exceeded') {
            toast.error(
              'Se ha bloqueado su acceso por intentos fallidos. Por favor, intente luego.'
            );
          } else if (
            error.message ===
            'Temporary password has expired and must be reset by an administrator.'
          ) {
            toast.error('La contraseña temporal ha expirado\nContacte con un administrador');
          } else {
            toast.error('Usuario no autorizado.');
          }
          break;
        case 'UserNotConfirmedException':
          toast.error(
            'Por favor, debe verificar su cuenta mediante el enlace enviado a su correo electrónico.'
          );
          break;
        case 'PasswordResetRequiredException':
          toast.error('La contraseña debe restablecerse.');
          break;
        case 'UserNotFoundException':
          toast.error('Usuario no encontrado.');
          break;
        case 'LimitExceededException':
          toast.error('Intentos excedidos. Intente nuevamente más tarde.');
          break;
        case 'NetworkError':
          toast.error('Compruebe su conectividad a internet');
          break;
        default:
          toast.error('Por favor, intente nuevamente.');
          break;
      }
    }
  },

  async checkUser({ state, commit }): Promise<void> {
    if (state.userAuth) {
      const roleExists = state.userAuth.roles.includes('ROOT');
      if (roleExists) {
        await router.push({ path: '/dashboard/users' }).catch(() => {});
      } else {
        await Auth.signOut({ global: false });
        commit('setUserAuth', null);
        toast.error('No puede iniciar sesión con esta cuenta');
        await router.push({ path: '/login' }).catch(() => {});
      }
    }
  },

  async removeValues({ commit }): Promise<void> {
    commit('setUserAuth', null);
    const account = window.localStorage.getItem('remember-account');
    window.localStorage.clear();
    if (account) {
      window.localStorage.setItem('remember-account', account);
    }
    await router.push({ path: '/' }).catch(() => {});
  },

  async signOut({ dispatch }): Promise<void> {
    try {
      await Auth.signOut({ global: true });
      await dispatch('removeValues');
    } catch (e) {
      await dispatch('removeValues');
    }
  },
  async tryLogin({ state, commit }): Promise<void> {
    try {
      if (!state.userAuth) {
        commit('setLoadingApp', true);
        const tempUser = await Auth.currentAuthenticatedUser();
        commit('setUserAuth', tempUser);
      }
      commit('setLoadingApp', false);
    } catch (e) {
      commit('setLoadingApp', false);
    }
  },
};

export default actions;
