import { GetterTree } from 'vuex';
import { AuthState } from './state';
import { StateInterface } from '@/app/store';
import { UserAuth } from '@/app/models/auth';

const getters: GetterTree<AuthState, StateInterface> = {
  loadingLogin(state): boolean {
    return state.loadingLogin;
  },
  userAuth(state): UserAuth | null {
    return state.userAuth;
  },
  isLoggedIn(state): boolean {
    return !!state.userAuth;
  },
  role(state): string | null | undefined {
    return state.userAuth?.role;
  },
  loadingApp(state): boolean {
    return state.loadingApp;
  },
};

export default getters;
