import { CompanyModel } from '@/app/models/company';
import { CompaniesPagingParams, Pagination } from '@/app/models/paginate';
import { ErrorHandler } from '@/app/models/error';

export interface CompanyState {
  companies: CompanyModel[];
  isLoading: boolean;
  pagination: Pagination | null;
  pagingParams: CompaniesPagingParams;
  error: ErrorHandler;
  searchMode: boolean;
  filterMode: boolean;
}

function state(): CompanyState {
  return {
    companies: [],
    isLoading: false,
    pagination: null,
    pagingParams: new CompaniesPagingParams(1, 8),
    error: new ErrorHandler(),
    searchMode: false,
    filterMode: false,
  };
}

export default state;
