import { SpacesPagingParams, Pagination } from '@/app/models/paginate';
import { ErrorHandler } from '@/app/models/error';
import {SpaceModel} from "@/app/models/space";

export interface SpaceState {
  spaces: SpaceModel[];
  isLoading: boolean;
  pagination: Pagination | null;
  pagingParams: SpacesPagingParams;
  error: ErrorHandler;
  searchMode: boolean;
  filterMode: boolean;
}

function state(): SpaceState {
  return {
    spaces: [],
    isLoading: false,
    pagination: null,
    pagingParams: new SpacesPagingParams(1, 8),
    error: new ErrorHandler(),
    searchMode: false,
    filterMode: false,
  };
}

export default state;
