import { GetterTree } from 'vuex';
import { SpaceState } from './state';
import { StateInterface } from '@/app/store';
import { ErrorHandler } from '@/app/models/error';
import { CompaniesPagingParams, Pagination } from '@/app/models/paginate';
import removeEmpty from '@/app/common/functions/remove-empty';
import { SpaceModel } from '@/app/models/space';

const getters: GetterTree<SpaceState, StateInterface> = {
  isLoading(state): boolean {
    return state.isLoading;
  },
  error(state): ErrorHandler {
    return state.error;
  },
  searchMode(state): boolean {
    return state.searchMode;
  },
  filterMode(state): boolean {
    return state.filterMode;
  },
  spaces(state): SpaceModel[] {
    return state.spaces;
  },
  query(state): string {
    return state.pagingParams.query;
  },
  pagination(state): Pagination | null {
    return state.pagination;
  },
  pagingParams(state): CompaniesPagingParams {
    return removeEmpty<CompaniesPagingParams>(state.pagingParams);
  },
};

export default getters;
