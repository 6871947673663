import Amplify from 'aws-amplify';
import {
  AMPLIFY_AUTH_AUTHENTICATION_FLOW_TYPE,
  AMPLIFY_AUTH_IDENTITY_POOL_ID,
  AMPLIFY_AUTH_USER_POOL_ID,
  AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
  AMPLIFY_REGION,
  API_ENDPOINT,
  API_ENDPOINT_NAME,
} from '../../common/constants/app-constants';

Amplify.configure({
  // OPTIONAL - if your API requires authentication
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: AMPLIFY_AUTH_IDENTITY_POOL_ID,
    // REQUIRED - Amazon Cognito Region
    region: AMPLIFY_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: AMPLIFY_AUTH_USER_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: AMPLIFY_AUTH_AUTHENTICATION_FLOW_TYPE,
  },
  API: {
    endpoints: [
      {
        name: API_ENDPOINT_NAME,
        endpoint: API_ENDPOINT,
        region: AMPLIFY_REGION,
      },
    ],
  },
});
export default Amplify;
