import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { ErrorModel } from '@/app/models/error';
import Vue from 'vue';
import Toast from 'vue-toastification';
const $toast = Vue.use(Toast, {}).$toast;

@Module({ namespaced: true })
class ApiGatewayInterceptorModule extends VuexModule {
  /**************** Start - Actions ****************/
  @Action({ rawError: true })
  handleApiGatewayInterceptor(response: ErrorModel): void {
    const method = response.config.method.toUpperCase();
    const status = response.status;
    switch (status) {
      case 400:
        this.context.dispatch('modalError/handleModalError', { response }, { root: true });
        break;
      case 403:
        if (method === 'GET') {
          $toast.error('No, se pudo realizar la peticiön.');
        } else {
          this.context.dispatch(
            'modalError/modalCustomError',
            {
              message: 'EL usuario no tiene permisos para realizar esta acción',
              title: 'Ocurrió un problema',
            },
            { root: true }
          );
        }
        break;
      case 404:
        /* if (method === 'GET') {*/
        $toast.error(response?.data?.error?.message || 'No se encontraron resultados');
        /* }*/
        break;
      default:
        $toast.error('Ups, Ocurrio un error, intente nuevamante.');
        break;
    }
  }
  /**************** End - Actions ****************/
}

export default ApiGatewayInterceptorModule;
