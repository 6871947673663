import Vue from 'vue';
import Vuex from 'vuex';

import DeviceModule from '@/app/store/modules/device-module';
import ModalErrorModule from '@/app/store/modules/modal-error-module';
import SnackbarModule from '@/app/store/modules/snackbar-module';
import ApiGatewayInterceptorModule from '@/app/store/modules/api-gateway-interceptor-module';
import { CompanyState } from '@/app/store/company/state';
import companyModule from '@/app/store/company';
import { UserState } from '@/app/store/user/state';
import userModule from '@/app/store/user';
import { SpaceState } from '@/app/store/space/state';
import spaceModule from '@/app/store/space';
import { AuthState } from '@/app/store/auth/state';
import authModule from '@/app/store/auth';

Vue.use(Vuex);

export interface StateInterface {
  company: CompanyState;
  user: UserState;
  space: SpaceState;
  auth: AuthState;
}

export default new Vuex.Store<StateInterface>({
  modules: {
    device: DeviceModule,
    modalError: ModalErrorModule,
    snackbar: SnackbarModule,
    apiGatewayInterceptor: ApiGatewayInterceptorModule,
    company: companyModule,
    user: userModule,
    space: spaceModule,
    auth: authModule,
  },
});
