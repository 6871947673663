export const AMPLIFY_REGION = process.env.VUE_APP_AMPLIFY_REGION;
export const AMPLIFY_AUTH_IDENTITY_POOL_ID = process.env.VUE_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID;
export const AMPLIFY_AUTH_USER_POOL_ID = process.env.VUE_APP_AMPLIFY_AUTH_USER_POOL_ID;
export const AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID =
  process.env.VUE_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID;
export const API_ENDPOINT_NAME = process.env.VUE_APP_API_ENDPOINT_NAME;
export const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
export const AMPLIFY_AUTH_AUTHENTICATION_FLOW_TYPE =
  process.env.VUE_APP_AMPLIFY_AUTH_AUTHENTICATION_FLOW_TYPE;

export const SHOW_CONFIG_PAY = process.env.VUE_APP_SHOW_CONFIG_PAY;
export const MAX_FILE_SIZE: number = parseInt(process.env.VUE_APP_MAX_FILE_SIZE!);
