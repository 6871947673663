import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import store from '../store';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { role } from '../common/helpers/role';
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/login' },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/app/layouts/MainLayout.vue'),
    children: [
      {
        path: 'companies',
        component: () => import('@/app/views/companies-view/CompaniesView.vue'),
        name: 'companies',
        meta: {
          nameComponent: 'Compañias',
          requiresAuth: true,
          authorize: [role.admin],
        },
      },
      {
        path: 'devices',
        component: () => import('@/app/views/devices-view/DevicesView.vue'),
        name: 'devices',
        meta: {
          nameComponent: 'Dispositivos',
          requiresAuth: true,
          authorize: [role.admin],
        },
      },

      {
        path: 'spaces',
        component: () => import('@/app/views/spaces-view/SpacesView.vue'),
        name: 'spaces',
        meta: {
          nameComponent: 'Inmuebles',
          requiresAuth: true,
          authorize: [role.admin],
        },
      },

      {
        path: 'users',
        component: () => import('@/app/views/users-view/UsersView.vue'),
        name: 'users',
        meta: {
          nameComponent: 'Usuarios',
          requiresAuth: true,
          authorize: [role.admin],
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/app/views/auth/sign-in/SignIn.vue'),
    meta: {
      nameComponent: 'Login',
      requiresAuth: false,
      authorize: [],
    },
  },
  /*  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () =>
      import('@/components/users/password-operations/forgot-password/ForgotPassword'),
  },
  {
    path: '/forgot-password-submit',
    name: 'ForgotPasswordSubmit',
    component: () =>
      import('@/components/users/password-operations/forgot-password-submit/ForgotPasswordSubmit'),
  },*/
  {
    path: '/:notFound(.*)',
    component: () => import('@/app/views/NotFound.vue'),
    meta: {
      requiresAuth: false,
      authorize: [],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth' };
  },
});

/*router.beforeEach((to, from, next) => {
  store.dispatch('tryLogin');
  const { authorize } = to.meta!;
  const currentUser = store.getters.isAuthenticate;
  const currentUserRole = store.getters.role;

  if (authorize.length && !authorize.includes(currentUserRole)) {
    return next({ path: '/' });
  }

  if (to.name === 'login') {
    if (currentUser && !to.matched.some((record) => record.meta.requiresAuth)) {
      next({ name: 'users' });
    }
  }
  next();
});*/

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/tryLogin');
  const { authorize } = to.meta!;
  const isLoggedIn = store.getters['auth/isLoggedIn'];
  const role = store.getters['auth/role'];

  if (to.name !== 'login' && authorize.length) {
    window.localStorage.setItem('temp-route', JSON.stringify({ path: to.path.toString() }));
  }

  if (authorize.length && !authorize.includes(role)) {
    window.localStorage.removeItem('temp-route');
    return next({ path: '/' });
  }

  if (to.name === 'login' && isLoggedIn && role) {
    const tempRoute = JSON.parse(window.localStorage.getItem('temp-route') || '{}');
    const tempPath = tempRoute?.path;
    next({ path: tempPath || '/dashboards/users' });
  }
  next();
});

export default router;
