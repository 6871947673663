import { ActionTree } from 'vuex';
import { SpaceState } from './state';
import { StateInterface } from '@/app/store';
import { Pagination } from '@/app/models/paginate';
import { ErrorHandler } from '@/app/models/error';
/*import Vue from 'vue';*/
/*import Toast from 'vue-toastification';*/
import spacesServices from '@/app/services/spaces.services';

/*const $toast = Vue.use(Toast, {}).$toast;*/

const actions: ActionTree<SpaceState, StateInterface> = {
  async loadSpaces({ commit, getters }): Promise<void> {
    commit('setIsLoading', true);
    commit('setDefaultError');
    try {
      const response = await spacesServices.list(getters.pagingParams);
      commit('setSpaces', response.data);
      const pagination = new Pagination(
        response.page,
        response.pages,
        response.perPage,
        response.total
      );
      commit('setPagination', pagination);
    } catch (error) {
      const errorHandler = new ErrorHandler(error);
      commit('setError', errorHandler);
    } finally {
      commit('setIsLoading', false);
    }
  },
  searchEnd({ commit, dispatch }): void {
    commit('setPaginateQuery', null);
    commit('setQuery', '');
    dispatch('loadSpaces');
  },
};

export default actions;
