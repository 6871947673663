import { UserAuth } from '@/app/models/auth';

export interface AuthState {
  loadingLogin: boolean;
  userAuth: UserAuth | null;
  loadingApp: boolean;
}

function state(): AuthState {
  return {
    loadingLogin: false,
    userAuth: null,
    loadingApp: false,
  };
}

export default state;
