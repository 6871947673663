import { GetterTree } from 'vuex';
import { CompanyState } from './state';
import { StateInterface } from '@/app/store';
import {ErrorHandler} from "@/app/models/error";
import {CompanyModel} from "@/app/models/company";
import {CompaniesPagingParams, Pagination} from "@/app/models/paginate";
import removeEmpty from "@/app/common/functions/remove-empty";

const getters: GetterTree<CompanyState, StateInterface> = {
  isLoading(state): boolean {
    return state.isLoading;
  },
  error(state): ErrorHandler {
    return state.error;
  },
  searchMode(state): boolean {
    return state.searchMode;
  },
  filterMode(state): boolean {
    return state.filterMode;
  },
  companies(state): CompanyModel[] {
    return state.companies;
  },
  query(state): string {
    return state.pagingParams.query;
  },
  pagination(state): Pagination | null {
    return state.pagination;
  },
  pagingParams(state): CompaniesPagingParams {
    return removeEmpty<CompaniesPagingParams>(state.pagingParams);
  },
};

export default getters;
