export class Pagination {
  page: number;
  pages: number;
  perPage: number;
  total: number;

  constructor(page: number, pages: number, perPage: number, total: number) {
    this.page = page;
    this.pages = pages;
    this.perPage = perPage;
    this.total = total;
  }
}

export class PaginatedResult<T> {
  data: T;
  page: number;
  pages: number;
  perPage: number;
  total: number;
  constructor(data: T, page: number, pages: number, perPage: number, total: number) {
    this.data = data;
    this.page = page;
    this.pages = pages;
    this.perPage = perPage;
    this.total = total;
  }
}

export class PagingParams {
  page;
  perPage;
  query = '';
  order: 'DESC' | 'ASC' | undefined = 'DESC';
  constructor(page = 1, perPage = 8) {
    this.page = page;
    this.perPage = perPage;
  }
}

export class SpacesPagingParams extends PagingParams {}
export class CompaniesPagingParams extends PagingParams {}
export class UsersPagingParams extends PagingParams {}
export class DevicePagingParams extends PagingParams {
  linkStatus?: string = undefined;
}
